.contact-form-section {
    padding: 40px 20px;
    color: white;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .valid-icon{
    color: green;
  }
  
  .heading-column {
    padding: 20px;
  }
  
  .form-column {
    padding: 20px;
  }
  
  form {
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 10px;
    color: #100b5c;
  }
  
  form .form-group {
    margin-bottom: 15px;
  }
  
  form input,
  form select,
  form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  form .submit-btn {
    background-color: #100b5c;
    color: #fff;
    font-weight: 700;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  form .submit-btn:hover {
    background-color: #ffcc3c;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
  }
  
  @media (max-width: 768px) {
    .heading-column {
      text-align: center;
    }
  
    .row {
      flex-direction: column;
    }
  
    form {
      margin-top: 20px;
    }

    h2{
        text-align: center;
        font-size: 2rem;
    }
  }
  