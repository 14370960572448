/* LoginPage.css */

  /* Container styles for centering the card */
  .login-container {
    max-width: 100%;
    height: 100%;
  }
  
  /* Login Card Styling */
  .login-card {
    border-radius: 15px;
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    padding: 2rem;
    color: white;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .login-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
  }
  
  /* Card title and description */
  .card h2 {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .card p {
    font-size: 1rem;
  }
  
  /* Styling the buttons */
  .login-button {
    font-size: 1.1rem;
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 45px;
    border: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #c80d18;
    color: #fff;
    transform: scale(1.05);
  }
  
  .login-button:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  
  /* Responsive adjustments */
  .d-flex {
    gap: 15px;
  }
  
  @media (max-width: 576px) {
    .login-card {
      padding: 1.5rem;
    }
  
    .card h2 {
      font-size: 1.5rem;
    }
  
    button {
      font-size: 1rem;
      padding: 10px 15px;
    }
  }
  