.faq-section {
  padding: 50px 15px;
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .faq-section {
      padding: 30px 15px;
  }

  h2 {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 20px;
  }
}
