/* Main content container */
.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding: 10px;

}

/* Modal-like device frame styling */
.video-device-frame {
  position: relative;
  background: #100b5c;
  border-radius: 12px;  /* Device-like rounded corners */
  padding: 15px;
  width: 100%;
  max-width: 800px; /* Set a max width for the device frame */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video-device-frame.playing {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);  /* Darker shadow when playing */
}

iframe {
  width: 100%;
  height: 450px; /* Adjust to a suitable height */
  border-radius: 8px;
}

/* Play button styling */
.play-button {
  position: absolute;
  background-color: #100b5c;
  color: white;
  border: none;
  padding: 15px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button:hover {
  background-color: #4682b4;
}

/* Hide the default YouTube play button using its class */
.ytp-large-play-button {
  display: none !important;
}

/* For devices */
@media screen and (max-width: 768px) {
  iframe {
    height: 250px;
  }
}
