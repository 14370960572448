.footer {
  padding: 40px 20px;
  color: #6c757d;
  text-align: left;
  background-color: #f8f9fa;
  text-align: center;
  position: relative;
  width: 100%;
}

.footer-column {
  margin-bottom: 20px;
}


.footer-logo {
  max-width: 100%;
  height: 80px;
}

.footer-description {
  font-size: 14px;
  line-height: 1.8;
  color: #6c757d;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 15px;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #6c757d;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #007bff;
}

.social-icons a {
  color: #6c757d;
  font-size: 20px;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #007bff;
}

.footer-bottom {
  margin-top: 30px;
  border-top: 1px solid #e9ecef;
  padding-top: 15px;
}

.footer-text {
  font-size: 14px;
  color: #6c757d;
}
