.book-test-container {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
}

.test-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.test-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.book-btn{
  background-color: #100b5c;
  color: #ddd;
  border-radius: 12px;
  border: none;
  padding: 10px;
}

.book-btn:hover{
  background-color: #100b3c;
}

.test-card h3 {
  margin: 0 0 10px;
}

.test-card p {
  margin: 0 0 20px;
}


/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup form */
.popup-form {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-form h2 {
  margin-bottom: 20px;
}

/* Layout for subject selection inside popup */
.popup-form .subject-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.popup-form .subject-container {
  display: flex;
  justify-content: start;
  gap: 10px;
  width: 48%; /* Ensures two subjects fit in one row */
  box-sizing: border-box;
}

.popup-form .subject-container input {
  margin-right: 10px;
}

.popup-form .subject-container label {
  font-size: 16px;
}

/* Price Summary */
.price-summary {
  margin-top: 20px;
  width: 100%;
  text-align: right;
}

.form-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.confirm-btn, .cancel-btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 45%; /* Make buttons take up equal space */
}

.confirm-btn {
  background-color: green;
  color: white;
}

.cancel-btn {
  background-color: red;
  color: white;
}

.confirm-btn:hover {
  background-color: darkgreen;
}

.cancel-btn:hover {
  background-color: darkred;
}

